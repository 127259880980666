import { FC, Fragment, useState } from "react";

import { MenuItem, Select, SelectChangeEvent, Box } from "@mui/material";
import i18next from "i18next";

import { ISupportedLanguage, getSupportedLanguages } from "../../services/localizationService";

import "./SelectLanguage.scss";

interface ISelectLanguageProps {}

const SelectLanguage: FC<ISelectLanguageProps> = () => {
    const [languages] = useState<ISupportedLanguage[]>(getSupportedLanguages());
    const [defaultLanguage, setDefaultLanguage] = useState<string>(i18next.language);

    const handleChangeLanguage = (e: SelectChangeEvent) => {
        setDefaultLanguage(e.target.value);
        i18next.changeLanguage(e.target.value);
    };

    return (
        <Fragment>
            {languages.length > 1 && (
                <div className="SelectLanguage__wrapper">
                    <Select
                        defaultValue={defaultLanguage}
                        value={defaultLanguage}
                        variant="standard"
                        onChange={handleChangeLanguage}
                        renderValue={(value) => {
                            return (
                                <Box>
                                    <i className="fa-solid fa-earth-americas"></i>
                                    {languages.find((l) => l.locale === value)?.name}
                                </Box>
                            );
                        }}
                    >
                        {languages.map((l) => (
                            <MenuItem value={l.locale} key={l.locale}>
                                <span className="SelectLanguage__name">{l.name}</span>
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            )}
        </Fragment>
    );
};

export default SelectLanguage;
