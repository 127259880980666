import { FC } from "react";
import "./SignUpHeader.scss";

interface ISignUpHeaderProps {}

const SignUpHeader: FC<ISignUpHeaderProps> = () => {
    return <div className="SignUpHeader__header"></div>;
};

export default SignUpHeader;
