import { createBrowserRouter } from "react-router-dom";
import NotFoundPage from "../pages/notFound/NotFoundPage";
import SignUpPage from "../pages/signUp/SignUpPage";
import FakeMarketplace from "../pages/fakeMarketplace/FakeMarketplace";

const router = createBrowserRouter([
    { path: "/marketplace", element: <FakeMarketplace /> },
    { path: "/", element: <SignUpPage /> },
    { path: "*", element: <NotFoundPage /> },
]);

export default router;
