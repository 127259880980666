import { FC, ReactNode } from "react";
import "./CenterContent.scss";

interface ICenterContentProps {
    children: ReactNode;
}

const CenterContent: FC<ICenterContentProps> = ({ children }) => {
    return (
        <div className="CenterContent__container">
            <div>{children}</div>
        </div>
    );
};

export default CenterContent;
