import { FC } from "react";
import "./Paragraph.scss";

interface IParagraphProps {
    title?: string;
    messages?: string | string[];
    listItems?: string[];
    disabled?: boolean;
    paragraphSize?: "small" | "large";
}

const Paragraph: FC<IParagraphProps> = ({ title, messages, listItems, disabled = false, paragraphSize = "large" }) => {
    const getSizeClasses = (): string[] => {
        const classes: string[] = [];
        switch (paragraphSize) {
            case "small":
                classes.push("Paragraph__small");
                break;
            case "large":
                classes.push("Paragraph__large");
                break;
        }
        return classes;
    };

    const getContainerClasses = (): string => {
        let classes: string[] = ["Paragraph__container"];
        classes = [...classes, ...getSizeClasses()];
        return classes.join(" ");
    };

    const getTitleClasses = (): string => {
        let classes: string[] = ["Paragraph__title"];
        classes = [...classes, ...getSizeClasses()];
        if (disabled) classes.push("Paragraph__disabled");
        return classes.join(" ");
    };

    const getMessageClasses = (additionalClasses?: string[]): string => {
        let classes: string[] = ["Paragraph__message"];
        if (additionalClasses) classes = [...classes, ...additionalClasses];
        if (disabled) classes.push("Paragraph__disabled");
        return classes.join(" ");
    };

    const renderMessages = (data: string | string[]) => {
        if (Array.isArray(data)) {
            return data.map((message, index) => {
                return index === 0 ? (
                    <div key={message} className={getMessageClasses(["Paragraph__messageSpacer"])}>
                        {message}
                    </div>
                ) : (
                    <div key={message} className={getMessageClasses()}>
                        {message}
                    </div>
                );
            });
        } else {
            return <div className={getMessageClasses(["Paragraph__messageSpacer"])}>{data}</div>;
        }
    };

    const renderListItems = (data: string[]) => {
        return (
            <ul>
                {data.map((item, index) => (
                    <li key={`item_${index}`}>{item}</li>
                ))}
            </ul>
        );
    };

    return (
        <div className={getContainerClasses()}>
            {title && <div className={getTitleClasses()}>{title}</div>}
            {messages && renderMessages(messages)}
            {listItems && renderListItems(listItems)}
        </div>
    );
};

export default Paragraph;
