import { FC, Fragment } from "react";
import { Modal } from "@mui/material";

import {
    ITermsOfServiceBody,
    ITermsOfServiceList,
    ITermsOfServiceParagraph,
    ITermsOfServiceTranslationData,
} from "./models/termsOfService.models";
import Paragraph from "../paragraph/Paragraph";

import "./TermsOfService.scss";

interface ITermsOfServiceProps {
    openModal: boolean;
    onClose: () => void;
    i18nData: ITermsOfServiceTranslationData | undefined;
}

const TermsOfService: FC<ITermsOfServiceProps> = ({ onClose, openModal, i18nData }) => {
    const renderContent = (data: ITermsOfServiceTranslationData | undefined) => {
        if (!data || !openModal) return <Fragment></Fragment>;

        return (
            <div className="TermsOfService_modal">
                {renderTitle(data)}
                {renderBody(data)}
            </div>
        );
    };

    const renderTitle = (data: ITermsOfServiceTranslationData) => {
        if (!data?.title) return null;

        return <div className="TermsOfService_modalTitle">{data.title}</div>;
    };

    const renderBody = (data: ITermsOfServiceTranslationData) => {
        if (!data?.body) return null;

        return <div className="TermsOfService_modalBody">{renderParagraph(data.body)}</div>;
    };

    const renderParagraph = (data: ITermsOfServiceBody) => {
        const pKeys = Object.keys(data);
        if (!pKeys.some((k) => k.startsWith("paragraph_"))) return null;

        return pKeys.map((k) => {
            if (k.startsWith("paragraph_")) {
                const content = data[k] as ITermsOfServiceParagraph;

                const title = content?.title as string | undefined;

                const message = content?.message as string | undefined;

                let messages: string[] | undefined;
                const mKeys = Object.keys(content).filter((mk) => mk.startsWith("message_"));
                if (mKeys.length > 0) {
                    messages = [];
                    mKeys.forEach((mk) => messages?.push(content[mk] as string));
                }

                let listItems: string[] | undefined;
                const list = content?.list as ITermsOfServiceList;
                if (list) {
                    const lKeys = Object.keys(list).filter((lk) => lk.startsWith("item_"));
                    if (lKeys.length > 0) {
                        listItems = [];
                        lKeys.forEach((lk) => listItems?.push(list[lk] as string));
                    }
                }

                return (
                    <Paragraph key={k} title={title} messages={message || messages} listItems={listItems} paragraphSize="small" />
                );
            }

            return <Fragment></Fragment>;
        });
    };

    return (
        <Modal open={openModal} onClose={onClose}>
            {renderContent(i18nData)}
        </Modal>
    );
};

export default TermsOfService;
