import { Link } from "@mui/material";
import { FC } from "react";

import { useTranslationWithPrefix } from "../../hooks/useTranslationWithPrefix";

import "./UserDetails.scss";

interface IUserDetailsProps {
    picture?: string;
    fullName: string;
    email: string;
    onChange: () => void;
}

const UserDetails: FC<IUserDetailsProps> = ({ picture, email, fullName, onChange }) => {
    const { t } = useTranslationWithPrefix("sign_up_form.user_details");

    return (
        <div className="global__topMargin40 UserDetails__wrapper">
            <div className="UserDetails__col">
                <img
                    alt="avatar"
                    src={picture && picture !== "" ? picture : "./assets/images/user_icon.png"}
                    className={picture && picture !== "" ? "" : "UserDetails__iconPlaceholder"}
                />
            </div>
            <div className="UserDetails__col">
                <div>{fullName}</div>
                <div>{email}</div>
            </div>
            <div className="UserDetails__col">
                <Link onClick={onChange}>{t("change")}</Link>
            </div>
        </div>
    );
};

export default UserDetails;
