import { useTranslation } from "react-i18next";

type TranslationFunc = (key: string) => string;

interface ITranslationWithPrefixResponse {
    troot: TranslationFunc;
    t: TranslationFunc;
}

export const useTranslationWithPrefix = (
    prefix: string
): ITranslationWithPrefixResponse => {
    const { t } = useTranslation();

    const translate: TranslationFunc = (key: string) => {
        return t(`${prefix}.${key}`);
    };

    return { troot: t, t: translate };
};
