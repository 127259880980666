import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CenterContent from "../../components/highOrder/centerContext/CenterContent";
import SignUpForm from "../../components/signUp/SignUpForm";
import SignUpHeader from "../../components/signUp/SignUpHeader";
import SelectLanguage from "../../components/selectLanguage/SelectLanguage";

interface ISignUpPageProps {}

const SignUpPage: FC<ISignUpPageProps> = () => {
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t("sign_up");
    }, [t]);

    return (
        <CenterContent>
            <SelectLanguage />
            <SignUpHeader />
            <SignUpForm />
        </CenterContent>
    );
};

export default SignUpPage;
