import { FC, useEffect, useState } from "react";

import { useTranslationWithPrefix } from "../../hooks/useTranslationWithPrefix";
import Paragraph from "../paragraph/Paragraph";

import "./SignUpSuccess.scss";
import { Button } from "@mui/material";

interface ISignUpSuccessProps {}

const SignUpSuccess: FC<ISignUpSuccessProps> = () => {
    const { t } = useTranslationWithPrefix("sign_up_form");
    // uncomment to enable self closing in 10 seconds
    const [closeMessage, setCloseMessage] = useState<string>("");

    // uncomment to enable self closing in 10 seconds
    useEffect(() => {
        let counter = 10;
        const cancelHandler = setInterval(() => {
            setCloseMessage(t("sign_up.close_message").replace("{0}", counter.toString()));
            counter -= 1;
            if (counter === 0) {
                clearInterval(cancelHandler);
            }
        }, 1000);
    }, [t]);

    return (
        <div className="SignUpSuccess__wrapper">
            <Paragraph title={t("sign_up.success_title")} messages={t("sign_up.success_message")} />
            {/* uncomment to enable self closing in 10 seconds */}
            <Paragraph messages={closeMessage} />
            <div className="SignUpSuccess__closeButton">
                <Button variant="contained" type="submit" onClick={() => window.close()}>
                    Close
                </Button>
            </div>
        </div>
    );
};

export default SignUpSuccess;
