import localConfig from "../shared/config.json";
import { ISupportedLanguage } from "../services/localizationService";
import axios, { AxiosError } from "axios";

export interface IConfiguration {
    localization: {
        supportedLanguages: ISupportedLanguage[];
    };
    autoCloseConfirmationInSeconds: number;
    pages: {
        signUp: {
            interestedInMaxLength: number;
        };
    };
    api: {
        baseUrl: string;
        path: {};
    };
    security: {
        google: {
            sso_client_id: string;
            gcpTokenKey: string;
        };
    };
}

let _config: any = null;

export const useConfiguration = (): Promise<IConfiguration> => {
    if (!_config) {
        return new Promise(async (resolve) => {
            const url = "/config";
            const baseURL = process.env.REACT_APP_API_CONFIG_BASE_URL;
            try {
                const cfg = await axios({
                    url,
                    method: "GET",
                    baseURL,
                });
                _config = cfg.data;
                resolve(_config);
            } catch (err) {
                const error = err as AxiosError;
                console.log("Using the fallback configuration with the following exception:");
                console.log("Error message:", error.message);
                console.log("Base URL:", baseURL);
                console.log("Path:", url);
                _config = localConfig;
                resolve(_config);
            }
        });
    } else {
        return new Promise((resolve) => {
            resolve(_config);
        });
    }
};
