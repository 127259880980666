import { FC, useState } from "react";

import { Button } from "@mui/material";
import axios, { AxiosError } from "axios";

import CenterContent from "../../components/highOrder/centerContext/CenterContent";

interface IFakeMarketplaceProps {}

const FakeMarketplace: FC<IFakeMarketplaceProps> = () => {
    const token =
        "x-gcp-marketplace-token=eyJhbGciOiJSUzI1NiIsImtpZCI6IjI4Y2Q0YTExMjA4ZjhhZTRkNTFlZWY2OWM3ZTNkNTAyYmFiYTUwYjMiLCJ0eXAiOiJKV1QifQ.eyJzdWIiOiJjYWJlZmM3ZS0zMDVmLTQ4MDEtYmI2OC05MTczZTNmMWY0ZDgiLCJhdWQiOiJjNmQ5LTI2MDEtNjQ0LTkzODAtMTEyMC05MDQxLTg0MTItNmY4OC01Y2E4Lm5ncm9rLWZyZWUuYXBwIiwiaWF0IjoxNjgzOTEzNjg5LCJleHAiOjE2ODM5MTM5ODksImdvb2dsZSI6eyJ1c2VyX2lkZW50aXR5IjoiMTAwOTk5OTQ0NDkzNjM1MTgxMTY4Iiwicm9sZXMiOlsiYWNjb3VudF9hZG1pbiJdfSwiaXNzIjoiaHR0cHM6Ly93d3cuZ29vZ2xlYXBpcy5jb20vcm9ib3QvdjEvbWV0YWRhdGEveDUwOS9jbG91ZC1jb21tZXJjZS1wYXJ0bmVyQHN5c3RlbS5nc2VydmljZWFjY291bnQuY29tIn0.rwMsh6RIaneBIht-Z6KaieruYLrFpILTmnr3A2-G_sExleN6puLohnszyBldWRMo4ZY-LfsJW7wIwLsduJ0r_ckReRp4Tc7KOQ0ap9OGheyrZR_SJ0o4oFMtrSXxjn71-chuePQT6nm8uzUYCOEI9TrFG3Pgkm6D4yQb9Op4q15YMzRJMBXzaSMiBpZNgXZv9o3OLE2cpDXnTDfpFGApLrGVcLbdRD0CUveTUhAVhllXyHz10weDn_wLZGlT8yGjW_CLnyGBNrS6fbNCccEZ8-S_bWhalDHAHLcmgrMWF3BSO62xti0-KNvPBvdVHeXz6QbbvX3wwxt4kUTQF8Z5Dw";
    const url = "/";
    const baseURL = "http://localhost:3021";

    const [error, setError] = useState<string>(baseURL);

    const handleRegister = async () => {
        try {
            const response = await axios({ url, method: "POST", data: token, baseURL });
            if (response.status === 200) {
                window.open(baseURL + url, "_self");
            }
        } catch (e) {
            const err = e as AxiosError;
            setError(err.message);
            setTimeout(() => {
                setError("");
            }, 5000);
        }
    };

    return (
        <CenterContent>
            <Button variant="contained" onClick={handleRegister}>
                Register with mCloud
            </Button>
            <div style={{ paddingTop: "20px", fontWeight: "bold", color: "red" }}>{error}</div>
        </CenterContent>
    );
};

export default FakeMarketplace;
