import { FC, Fragment, useEffect, useRef, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { Button, Link, MenuItem, TextField, TextareaAutosize } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import jwt_decode from "jwt-decode";
import UserDetails from "./UserDetails";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import axios, { AxiosError } from "axios";
import Cookies from "js-cookie";

import { useTranslationWithPrefix } from "../../hooks/useTranslationWithPrefix";
import { getLocations } from "../../services/dictionaryService";
import TermsOfService from "./TermsOfService";
import Paragraph from "../paragraph/Paragraph";
import { IGoogleAccountInformation, ISignUpForm, ISignUpPayload } from "./models/signUp.models";
import { ResponseTypes } from "./models/signUp.enums";

import "./SignUpForm.scss";
import { toast } from "react-toastify";
import SignUpSuccess from "./SignUpSuccess";
import { ITermsOfServiceTranslationData } from "./models/termsOfService.models";
import i18next from "i18next";
import { IConfiguration, useConfiguration } from "../../hooks/useConfiguration";

interface ISignUpFormProps {}

const SignUpForm: FC<ISignUpFormProps> = () => {
    const configAsync = useConfiguration();
    const { t } = useTranslationWithPrefix("sign_up_form");

    const [locationData, setLocationData] = useState<string[]>([]);
    const [openModal, setOpenModal] = useState(false);
    const [formDisabled, setFormDisabled] = useState(true);
    const [user, setUser] = useState<IGoogleAccountInformation | null>(null);
    const [authToken, setAuthToken] = useState<string | null>(null);
    const [responseType, setResponseType] = useState<ResponseTypes>(ResponseTypes.notSet);
    const [termsData, setTermsData] = useState<ITermsOfServiceTranslationData>();
    const [config, setConfig] = useState<IConfiguration>();
    const [configLoaded, setConfigLoaded] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);

    const schema = yup.object({
        organizationName: yup.string().required(t("organization.validation_error_required")),
        phoneNumber: yup
            .string()
            .nullable()
            .test("validate-phone-number", "", function (value) {
                if (value && value !== "") {
                    // The translation is not needed here since this is a default value.
                    return matchIsValidTel(value) ? true : this.createError({ message: "Invalid phone number." });
                } else {
                    return true;
                }
            }),
    });

    const signInButtonContainerRef = useRef<HTMLDivElement>(null);
    const handleCredentialResponse = (response: google.accounts.id.CredentialResponse) => {
        setUser(() => {
            (async () => {
                try {
                    setFormDisabled(false);
                    setAuthToken(response.credential);
                } catch {
                    throw new Error("Authentication error!");
                }
            })();

            return jwt_decode<IGoogleAccountInformation>(response.credential);
        });
    };

    useEffect(() => {
        if (configLoaded) {
            google.accounts.id.initialize({
                client_id: config?.security.google.sso_client_id || "",
                callback: handleCredentialResponse,
            });

            const parent = signInButtonContainerRef.current || new HTMLElement();
            google.accounts.id.renderButton(parent, {
                type: "standard",
                shape: "square",
                theme: "filled_blue",
                text: "signin_with",
            });

            setLocationData(getLocations());
            setTermsData(
                i18next.getDataByLanguage(i18next.language)?.translation
                    ?.terms_of_service as unknown as ITermsOfServiceTranslationData
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [configLoaded]);

    useEffect(() => {
        (async () => {
            const cfg = await configAsync;
            setConfig(cfg);
            setConfigLoaded(true);
        })();
    }, [configAsync]);

    useEffect(() => {
        if (responseType === ResponseTypes.error) {
            toast.error(t("sign_up.error"));
        }
        if (responseType === ResponseTypes.duplicate) {
            toast.error(t("sign_up.duplicate"));
        }
    }, [responseType, t]);

    const handleChangeUser = () => google.accounts.id.prompt();
    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);

    const formHook = useForm<ISignUpForm>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });
    const onSubmit: SubmitHandler<ISignUpForm> = async (data) => {
        setSubmitting(true);

        const payload: ISignUpPayload = {
            userId: user ? user.sub : "",
            organizationName: data.organizationName,
            name: user ? user.name : "",
            email: user ? user.email : "",
            phoneNumber: data.phoneNumber?.replaceAll(" ", ""),
            interestedIn: data.interestedIn,
            country: data.country,
        };

        try {
            const response = await axios({
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
                url: `${Cookies.get("update-path")}`,
                method: "PUT",
                data: payload,
                baseURL: config?.api.baseUrl,
            });
            setResponseType(response.status === 200 ? ResponseTypes.success : ResponseTypes.error);
            // uncomment to enable self closing in 10 seconds
            if (response.status === 200) {
                setTimeout(() => {
                    window.close();
                }, config?.autoCloseConfirmationInSeconds);
            }
        } catch (err) {
            setSubmitting(false);
            if (axios.isAxiosError(err)) {
                const error = err as AxiosError;
                setResponseType(error.response?.status === 412 ? ResponseTypes.duplicate : ResponseTypes.error);
            } else {
                setResponseType(ResponseTypes.notSet);
                throw err;
            }
        }
    };

    return (
        <Fragment>
            {responseType === ResponseTypes.success && <SignUpSuccess />}
            {responseType !== ResponseTypes.success && (
                <form onSubmit={formHook.handleSubmit(onSubmit)}>
                    <div className="SignUpForm__container">
                        <Paragraph title={t("welcome.title")} messages={t("welcome.message")} />
                        <div
                            className={user ? "global__topMargin40 SignUpForm__hidden" : "global__topMargin40"}
                            ref={signInButtonContainerRef}
                        ></div>
                        {user && (
                            <UserDetails
                                fullName={user.name}
                                email={user.email}
                                picture={user.picture}
                                onChange={handleChangeUser}
                            />
                        )}
                        <Paragraph title={t("organization.title")} messages={t("organization.message")} disabled={formDisabled} />
                        <TextField
                            variant="standard"
                            label={t("organization.organization_name")}
                            {...formHook.register("organizationName")}
                            disabled={formDisabled}
                            autoComplete="off"
                            error={formHook.formState.errors.organizationName !== undefined}
                            helperText={
                                formHook.formState.errors.organizationName !== undefined
                                    ? t("organization.validation_error_required")
                                    : t("helper_required")
                            }
                        />
                        <Paragraph
                            title={t("interested_in.title")}
                            messages={t("interested_in.message")}
                            disabled={formDisabled}
                        />
                        <TextareaAutosize
                            minRows="5"
                            maxLength={config?.pages.signUp.interestedInMaxLength}
                            {...formHook.register("interestedIn")}
                            disabled={formDisabled}
                        />
                        <p className="MuiFormHelperText-root MuiFormHelperText-sizeMedium SignUpForm__textareaHelper">
                            {t("helper_not_required")}
                        </p>
                        <Paragraph title={t("location.title")} disabled={formDisabled} />
                        <TextField
                            select
                            variant="standard"
                            defaultValue={""}
                            {...formHook.register("country")}
                            disabled={formDisabled}
                            label="Select your location"
                            helperText={t("helper_not_required")}
                        >
                            {locationData.map((text) => (
                                <MenuItem key={text} value={text}>
                                    {text}
                                </MenuItem>
                            ))}
                        </TextField>
                        <Paragraph title={t("contact.title")} messages={t("contact.message")} disabled={formDisabled} />
                        <Controller
                            name="phoneNumber"
                            control={formHook.control}
                            render={({ field: { value, onChange } }) => {
                                return (
                                    <MuiTelInput
                                        forceCallingCode={false}
                                        variant="standard"
                                        value={value}
                                        error={formHook.formState.errors.phoneNumber !== undefined}
                                        disabled={formDisabled}
                                        autoComplete="off"
                                        onChange={onChange}
                                        helperText={
                                            formHook.formState.errors.phoneNumber !== undefined
                                                ? t("contact.validation_error_pattern")
                                                : t("helper_not_required")
                                        }
                                    />
                                );
                            }}
                        />
                        <div
                            className={
                                formDisabled ? "SignUpForm__disabled SignUpForm_registerMessage" : "SignUpForm_registerMessage"
                            }
                        >
                            {t("register.message_part_1")}
                            {formDisabled ? (
                                t("register.here")
                            ) : (
                                <Link underline="hover" onClick={handleOpen}>
                                    {t("register.here")}
                                </Link>
                            )}
                            {t("register.message_part_2")}
                        </div>
                        <Button
                            variant="contained"
                            type="submit"
                            disabled={formDisabled || !formHook.formState.isValid || submitting}
                        >
                            {t("register.button_caption")}
                        </Button>
                        <TermsOfService openModal={openModal} onClose={handleClose} i18nData={termsData} />
                    </div>
                </form>
            )}
        </Fragment>
    );
};

export default SignUpForm;
