import { FC, Fragment } from "react";
import { useTranslation } from "react-i18next";

interface INotFoundPageProps {}

const NotFoundPage: FC<INotFoundPageProps> = () => {
    const { t } = useTranslation();

    return <Fragment>{t("page_not_found")}</Fragment>;
};

export default NotFoundPage;
