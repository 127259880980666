import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import router from "./shared/routes";
import { RouterProvider } from "react-router-dom";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { ToastContainer } from "react-toastify";

import { getSupportedLocale, getFallbackLanguage } from "./services/localizationService";

import "react-toastify/dist/ReactToastify.css";
import "./index.scss";

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
        supportedLngs: getSupportedLocale(),
        // fallbackLng: getFallbackLanguage().locale,
        lng: getFallbackLanguage().locale,
        detection: {
            order: [
                "localStorage",
                "htmlTag",
                // 'querystring',
                // 'cookie',
                // 'sessionStorage',
                // 'navigator',
                // 'path',
                // 'subdomain',
            ],
            caches: ["localStorage"],
        },
        backend: {
            loadPath: "/assets/locales/{{lng}}.json",
        },
    });

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const loadingMarkup = (
    <div>
        <h3>Loading...</h3>
    </div>
);

root.render(
    <React.StrictMode>
        <Suspense fallback={loadingMarkup}>
            <ToastContainer position="top-right" />
            <RouterProvider router={router} />
        </Suspense>
    </React.StrictMode>
);

// reportWebVitals();
