import i18next from "i18next";
import config from "../shared/config.json";

export interface ISupportedLanguage {
    name: string;
    locale: string;
    code: string;
    isFallbackLng?: boolean;
}

export function getSupportedLanguages(): ISupportedLanguage[] {
    return config.localization.supportedLanguages;
}

export function getSupportedLocale(): string[] {
    return getSupportedLanguages().map((x) => x.locale);
}

export function getFallbackLanguage(): ISupportedLanguage {
    if (getSupportedLanguages().length === 0) {
        const err = i18next.t("missing_locale_error");
        throw new Error(err);
    }

    const fallbackLng = getSupportedLanguages().find((x) => x.isFallbackLng);
    if (!fallbackLng) return getSupportedLanguages()[0];

    return fallbackLng;
}
